/**
 * station schema
 */

import rules from './validationRules';
import i18n from '../i18n';
import { datatypes, getBooleanTypeReferences } from './datatypes';
import { getItemStatusTypeReferences } from './itemStatus';
import { roleAdmin, roleSuperAdmin } from './accessRolesModel';

const stationFieldId = {
  name: 'id',
  type: datatypes.string,
  description: 'Unique id of the station',
  label: '',
  allowedRoles: {
    read: [],
    write: [],
  },
};

const stationFieldStationName = {
  name: 'stationName',
  type: datatypes.string,
  description: 'Name given to the station',
  minLength: 1,
  maxLength: 128,
  label: i18n.tc('station.fields.stationName', 1),
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleSuperAdmin],
  },
  rules: [rules.required],
};

const stationFieldStationParams = {
  name: 'stationParams',
  type: datatypes.json,
  description: 'Specific config parameters for the station',
  label: i18n.tc('station.fields.stationParams', 1),
  minLength: 0,
  maxLength: 4096,
  rules: [rules.isJson],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [roleSuperAdmin],
  },
};

const stationFieldIpAddress = {
  name: 'ipAddress',
  type: datatypes.string,
  description: 'IP address of the station',
  label: i18n.tc('station.fields.ipAddress', 1),
  minLength: 0,
  maxLength: 64,
  rules: [rules.isIPv4Address],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [roleSuperAdmin],
  },
};

const stationFieldMacAddress = {
  name: 'macAddress',
  type: datatypes.string,
  description: 'MAC address of the station',
  label: i18n.tc('station.fields.macAddress', 1),
  minLength: 0,
  maxLength: 64,
  rules: [rules.isMacAddress],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [roleSuperAdmin],
  },
};

const stationFieldStationIsActive = {
  name: 'stationIsActive',
  type: datatypes.boolean,
  description: 'The station is currently active.',
  label: i18n.tc('station.fields.stationIsActive', 1),
  references: getBooleanTypeReferences(),
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleSuperAdmin],
  },
  rules: [],
};

const stationFieldItemStatus = {
  name: 'itemStatus',
  type: datatypes.itemStatus,
  description: 'Etat de l élément.',
  label: i18n.tc('station.fields.itemStatus', 1),
  references: getItemStatusTypeReferences(),
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
  rules: [],
};

const station = {
  fields: [
    stationFieldId,
    stationFieldStationName,
    stationFieldStationParams,
    stationFieldIpAddress,
    stationFieldMacAddress,
    stationFieldStationIsActive,
    stationFieldItemStatus,
  ],
  crudAllowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    readInactive: [roleSuperAdmin],
    create: [roleSuperAdmin],
    update: [roleSuperAdmin],
    delete: [roleSuperAdmin],
  },
};

export default station;
export {
  station,
  stationFieldId,
  stationFieldStationName,
  stationFieldStationParams,
  stationFieldIpAddress,
  stationFieldMacAddress,
  stationFieldStationIsActive,
  stationFieldItemStatus,
};
